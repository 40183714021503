import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useTemplateAPI = (
    search = "",
    categories = undefined,
    grades = undefined,
    resource_types = undefined,
    formats = undefined,
    order = "-room_count",
    page = 1,
    auth
) => {
    const { getAccessTokenSilently } = useAuth0();
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        setLoading(true);
        if (auth && !auth.isLoading && !auth.isProfileLoading) {
            try {
                let link =
                    process.env.REACT_APP_MM_API_URL +
                    "templates/list?page=" +
                    page;

                if (search) {
                    link = link + "&search=" + search;
                }

                if (order) {
                    link = link + "&ordering=" + order;
                }

                if (categories) {
                    const category_titles = categories
                        .map((i) => i.title)
                        .join(",");

                    link = link + "&categories=" + category_titles;
                }

                if (grades) {
                    const grade_titles = grades.map((i) => i.title).join(",");
                    link = link + "&grades=" + grade_titles;
                }

                if (resource_types) {
                    const resource_type_titles = resource_types
                        .map((i) => i.title)
                        .join(",");
                    link = link + "&resource_types=" + resource_type_titles;
                }

                if (formats) {
                    if (formats.length === 1 && formats[0].value === "room") {
                        // Only Rooms
                        link = link + "&assignment_mode=false";
                    } else if (
                        formats.length === 1 &&
                        formats[0].value === "student_project"
                    ) {
                        // Only Student Projects
                        link = link + "&assignment_mode=true";
                    } else {
                        // Both or none, we skip
                        console.log("BOTH");
                    }
                }

                if (auth.profile) {
                    // Authenticated User
                    getAccessTokenSilently().then((token) => {
                        fetch(link, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        })
                            .then((resp) => {
                                return resp.json();
                            })
                            .then((completeResp) => {
                                setRooms(completeResp);
                                setLoading(false);
                            });
                    });
                } else {
                    // Guest User
                    fetch(link)
                        .then((resp) => {
                            return resp.json();
                        })
                        .then((completeResp) => {
                            setRooms(completeResp);
                            setLoading(false);
                        });
                }
            } catch (e) {
                console.log("Error!", e);
                console.error(e);
                setLoading(false);
                setError(e);
            }
        }
    }, [
        getAccessTokenSilently,
        refreshIndex,
        page,
        categories,
        grades,
        resource_types,
        formats,
        search,
        order,
        auth,
    ]);

    return [rooms, loading, error, refresh];
};

export default useTemplateAPI;
