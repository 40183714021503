import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import { useNavigate, Link } from "react-router-dom";
import { Presence } from "phoenix";
import { toast } from "react-hot-toast";
import ReactGA from "react-ga4";
import moment from "moment";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import RoomSecurityModal from "../modals/RoomSecurityModal";
import { RealtimeModal } from "../modals/RealTimeModal";

import { getPicture } from "../../../utils/get-picture";
import { cloneRoom } from "../../../utils/create-room";
import UnarchiveModal from "../modals/UnarchiveModal";
import DeleteClassroomModal from "../modals/DeleteClassroomModal";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        variant="none"
        className="text-dark"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <i className="bi bi-list" style={{ fontSize: "1.4rem" }}></i>
    </Button>
));

export default function ArchivedClassRoomCard({
    auth,
    classroom,
    refresh,
    socket,
    team,
    profile,
    disabled,
    restricted,
    restrictedStudentProjects,
    showInviteModal,
    showAssignModal,
    newRoom,
    refreshSingleRoom,
}) {
    const [socketsAttempted, setSocketsAttempted] = useState(false);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [channel, setChannel] = useState(null);
    const navigate = useNavigate();

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (socket) {
            let ch = socket
                .channel("hub:" + classroom.hub_id, {
                    profile: {
                        displayName: "teacher_bot_2df",
                        avatarId: "",
                    },
                    context: {
                        mobile: false,
                        hmd: false,
                        discord: true,
                    },
                })
                .join()
                .receive("error", (e) => {
                    console.error("Could not connect to socket.channel", e);
                });

            setChannel(ch.channel);
            console.log("connected to channel", ch);

            let presences = new Presence(ch.channel);

            presences.onSync(() => {
                let listBy = (id, { metas: [first, ...rest] }) => {
                    first.count = rest.length + 1; // count of this user's presences
                    first.id = id;
                    return first;
                };

                let filteredList = presences
                    .list(listBy)
                    .filter((user) => user.context.discord !== true);

                setOnlineUsers(filteredList);
            });

            setSocketsAttempted(true);
        }
    }, [classroom.hub_id, socket]);

    return (
        <Col sm={12} md={12} lg={12} xxl={12}>
            <Tooltip id="classroomCard" />
            <Card className="">
                <Row className="p-0 m-0 g-0">                  
                    <Col
                        xs={12}
                        md={3}
                        className="p-3 m-0 d-flex flex-column justify-content-between align-content-start"
                        style={{
                            background:
                                "linear-gradient(119deg, rgba(52,232,27,0) 0%, rgba(0,0,0,0.4247898988697041) 58%, rgba(0,0,0,0.6516806551722252) 100%), url(" +
                                getPicture(classroom.scene.photo) +
                                ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <div className="d-flex flex-column align-self-start">
                            {socketsAttempted && classroom.channel?.status !== "error" ? (
                                <RealtimeModal
                                    classroomName={classroom.title}
                                    onlineUsers={onlineUsers}
                                />
                            ) : (
                                <button className="btn badge bg-light text-dark">
                                    0 / 25 Users
                                </button>
                            )}
                        </div>

                    </Col>
                    <Col className="">
                        <Row className="border-bottom m-0">
                            <Col
                                xs={12}
                                md={8}
                                className="d-flex justify-content-start align-items-center"
                            >
                                <Card.Title className="text-dark text-truncate mb-0 ms-2 pt-3 pt-md-0">
                                    {classroom.title}
                                </Card.Title>
                                {newRoom && (
                                    <span className="badge bg-success d-none d-md-block ms-2">
                                        Just created!
                                    </span>
                                )}
                                {classroom.locked && (
                                    <span className="badge bg-secondary d-none d-md-block me-2">
                                        Locked
                                    </span>
                                )}
                            </Col>
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex justify-content-end align-items-center p-1"
                            >
                                <Dropdown>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        variant="white"
                                        className="text-white"
                                        id="dropdown-basic"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <UnarchiveModal
                                            refresh={refresh}
                                            endpoint="classrooms"
                                            buttonText="Unarchive Your Lesson"
                                            title={classroom.title}
                                            object={classroom}
                                            profile={profile}
                                        />
                                        <DeleteClassroomModal
                                            refresh={refresh}
                                            endpoint="classrooms"
                                            buttonText="Delete"
                                            title={classroom.title}
                                            id={classroom.id}
                                            scope="delete:classroom"
                                            category="classrooms"
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className="m-0 mt-4 mb-4">
                            <Col
                                xs={12}
                                sm={4}
                                className="d-flex justify-content-start align-self-center d-none d-sm-block"
                            >
                                {onlineUsers.length > 0 ? (
                                    <p className="mb-0 fw-light text-dark ms-3">
                                        <small>Users online right now</small>
                                    </p>
                                ) : (
                                    <>
                                        {classroom.last_active ? (
                                            <small className="fw-light text-dark mb-0 ms-3">
                                                Last active{" "}
                                                {moment(
                                                    classroom.last_active
                                                ).fromNow()}
                                            </small>
                                        ) : (
                                            <small className="fw-light text-dark mb-0 ms-3"></small>
                                        )}
                                    </>
                                )}

                            </Col>
                            <Col
                                xs={12}
                                sm={8}
                                className="d-flex justify-content-end align-items-center"
                            >
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
}